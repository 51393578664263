
import React, { useState, useContext } from 'react';
import { AppBar, Toolbar, Box, Button, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SchoolIcon from '@mui/icons-material/School';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import StarsIcon from '@mui/icons-material/Stars';
import CommuteIcon from '@mui/icons-material/Commute';
import PeopleIcon from '@mui/icons-material/People';
import Ashokalogo from '../assets/ashok.png';
import Isnartechlogo from '../assets/newlogoisnar.png'; // Adjust path as needed
import { AuthContext } from './context/AuthContext';
import MenuIcon from '@mui/icons-material/Menu';

const Navbar = () => {
    const [anchorElAdmission, setAnchorElAdmission] = useState(null);
    const [anchorElFacility, setAnchorElFacility] = useState(null);
    const [anchorElCareers, setAnchorElCareers] = useState(null);
    const { isLoggedIn, logout } = useContext(AuthContext); // Accessing context
    const navigate = useNavigate(); // Navigate function from React Router

    const [menuAnchorEl, setMenuAnchorEl] = useState(null); // State for menu anchor element

    const handleMenuOpennav = (event) => {
        setMenuAnchorEl(event.currentTarget); // Open menu
    };

    const handleMenuClosenav = () => {
        setMenuAnchorEl(null); // Close menu
    };

    const handleMenuOpen = (event, menu) => {
        // Close other menus when opening a new one
        if (menu === 'admission') {
            setAnchorElAdmission(event.currentTarget);
            setAnchorElFacility(null);
            setAnchorElCareers(null);
        } else if (menu === 'facility') {
            setAnchorElFacility(event.currentTarget);
            setAnchorElAdmission(null);
            setAnchorElCareers(null);
        } else if (menu === 'careers') {
            setAnchorElCareers(event.currentTarget);
            setAnchorElAdmission(null);
            setAnchorElFacility(null);
        }
    };

    const handleMenuClose = (menu) => {
        if (menu === 'admission') {
            setAnchorElAdmission(null);
        } else if (menu === 'facility') {
            setAnchorElFacility(null);
        } else if (menu === 'careers') {
            setAnchorElCareers(null);
        }
    };

    const handleLoginLogout = () => {
        if (isLoggedIn) {
            logout(); // Call logout function if logged in
        } else {
            navigate('/loginPage'); // Navigate to login page if not logged in
        }
    };

    // const handleDashboardClick = () => {
    //     const userType = localStorage.getItem('userType');
    //     const schoolId = localStorage.getItem('schoolId');
    //     const classTeacherDetails = JSON.parse(localStorage.getItem('classTeacherDetails'));
    //     const rollNumber = localStorage.getItem('rollNumber');
    //     const _id = localStorage.getItem('_id');
    //     const standard = localStorage.getItem('standard');
    //     const section = localStorage.getItem('section');
    //     const fullName = localStorage.getItem('fullName');
    //     const schoolName = localStorage.getItem('schoolName');

    //     console.log(classTeacherDetails);
    //     // Navigate to respective dashboard based on userType
    //     if (userType === 'admin') {
    //         navigate('/Admin/dashboard', { state: { userType, schoolId, _id } });
    //     } else if (userType === 'student') {
    //         navigate('/StudentDashboard', { state: { userType, schoolId, rollNumber, _id, standard, section, fullName } });
    //     } else if (userType === 'faculty') {
    //         navigate('/FacultyDashboard', { state: { userType, schoolId, classTeacherDetails, _id, fullName } });
    //     } else if (userType === 'staff') {
    //         navigate('/StaffDashboard', { state: { userType, schoolId, _id } });
    //     } else {
    //         navigate('/Adminstrator/dashboard', { state: { schoolId, schoolName } });
    //     }
    // };

    const handleDashboardClick = () => {
        const userType = localStorage.getItem('userType');
        const schoolId = localStorage.getItem('schoolId');
        
        // Safely parse the classTeacherDetails
        let classTeacherDetails = null;
        try {
            classTeacherDetails = JSON.parse(localStorage.getItem('classTeacherDetails')) || null;
        } catch (error) {
            console.error("Error parsing classTeacherDetails:", error);
        }
    
        const rollNumber = localStorage.getItem('rollNumber');
        const _id = localStorage.getItem('_id');
        const standard = localStorage.getItem('standard');
        const section = localStorage.getItem('section');
        const fullName = localStorage.getItem('fullName');
        const schoolName = localStorage.getItem('schoolName');
    
        console.log(classTeacherDetails);
    
        // Navigate to respective dashboard based on userType
        if (userType === 'admin') {
            navigate('/Admin/dashboard', { state: { userType, schoolId, _id , fullName} });
        } else if (userType === 'student') {
            navigate('/StudentDashboard', { state: { userType, schoolId, rollNumber, _id, standard, section, fullName } });
        } else if (userType === 'faculty') {
            navigate('/FacultyDashboard', { state: { userType, schoolId, classTeacherDetails, _id, fullName } });
        } else if (userType === 'staff') {
            navigate('/StaffDashboard', { state: { userType, schoolId, _id, fullName } });
        } else {
            navigate('/Adminstrator/dashboard', { state: { schoolId, schoolName } });
        }
    };
    

    // Determine which logo to use based on schoolId
    const schoolId = localStorage.getItem('schoolId');
    const logoToShow = schoolId === 'sch-6309' ? Ashokalogo : Isnartechlogo;

    return (
        <StyledAppBar position="fixed">
            <StyledToolbar>

                <StyledLogo src={logoToShow} alt="Logo" />


                <MenuIconWrapper>
                    <MenuIcon onClick={handleMenuOpennav} className="mr-2"/>
                    <Menu
                        anchorEl={menuAnchorEl}
                        open={Boolean(menuAnchorEl)}
                        onClose={handleMenuClosenav}
                    >
                        <StyledButton component={RouterLink} to="/" color="inherit">Home</StyledButton>
                        <StyledButton component={RouterLink} to="/FoodAnalysis" color="inherit">Food Analysis</StyledButton>
                        <StyledButton
                            aria-controls="admission-menu"
                            aria-haspopup="true"
                            onClick={(e) => handleMenuOpen(e, 'admission')}
                            color="inherit"
                        >
                            Admission {anchorElAdmission ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </StyledButton>
                        <StyledMenu
                            id="admission-menu"
                            anchorEl={anchorElAdmission}
                            open={Boolean(anchorElAdmission)}
                            onClose={() => handleMenuClose('admission')}
                        >
                            <StyledMenuItem component={RouterLink} to="/enquiry" onClick={() => handleMenuClose('admission')}>
                                <ListItemIcon><SchoolIcon fontSize="small" /></ListItemIcon>
                                <ListItemText primary="Enquiry" />
                            </StyledMenuItem>
                            <StyledMenuItem component={RouterLink} to="/fee" onClick={() => handleMenuClose('admission')}>
                                <ListItemIcon><LibraryBooksIcon fontSize="small" /></ListItemIcon>
                                <ListItemText primary="Fee" />
                            </StyledMenuItem>
                        </StyledMenu>
                        <StyledButton
                            aria-controls="facility-menu"
                            aria-haspopup="true"
                            onClick={(e) => handleMenuOpen(e, 'facility')}
                            color="inherit"
                        >
                            Facility {anchorElFacility ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </StyledButton>
                        <StyledMenu
                            id="facility-menu"
                            anchorEl={anchorElFacility}
                            open={Boolean(anchorElFacility)}
                            onClose={() => handleMenuClose('facility')}
                        >
                            <StyledMenuItem component={RouterLink} to="/faculty" onClick={() => handleMenuClose('facility')}>
                                <ListItemIcon><SchoolIcon fontSize="small" /></ListItemIcon>
                                <ListItemText primary="Faculty" />
                            </StyledMenuItem>
                            <StyledMenuItem component={RouterLink} to="/library" onClick={() => handleMenuClose('facility')}>
                                <ListItemIcon><LibraryBooksIcon fontSize="small" /></ListItemIcon>
                                <ListItemText primary="Library" />
                            </StyledMenuItem>
                            <StyledMenuItem component={RouterLink} to="/extracurricular" onClick={() => handleMenuClose('facility')}>
                                <ListItemIcon><StarsIcon fontSize="small" /></ListItemIcon>
                                <ListItemText primary="ExtraCurricular" />
                            </StyledMenuItem>
                            <StyledMenuItem component={RouterLink} to="/transportation" onClick={() => handleMenuClose('facility')}>
                                <ListItemIcon><CommuteIcon fontSize="small" /></ListItemIcon>
                                <ListItemText primary="Transportation" />
                            </StyledMenuItem>
                        </StyledMenu>
                        <StyledButton
                            aria-controls="careers-menu"
                            aria-haspopup="true"
                            onClick={(e) => handleMenuOpen(e, 'careers')}
                            color="inherit"
                        >
                            Careers {anchorElCareers ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </StyledButton>
                        <StyledMenu
                            id="careers-menu"
                            anchorEl={anchorElCareers}
                            open={Boolean(anchorElCareers)}
                            onClose={() => handleMenuClose('careers')}
                        >
                            <StyledMenuItem component={RouterLink} to="/achievement" onClick={() => handleMenuClose('careers')}>
                                <ListItemIcon><StarsIcon fontSize="small" /></ListItemIcon>
                                <ListItemText primary="Achievement" />
                            </StyledMenuItem>
                            <StyledMenuItem component={RouterLink} to="/alumni" onClick={() => handleMenuClose('careers')}>
                                <ListItemIcon><PeopleIcon fontSize="small" /></ListItemIcon>
                                <ListItemText primary="Our Alumni" />
                            </StyledMenuItem>
                        </StyledMenu>
                        {isLoggedIn ? (
                            <>
                                <MenuItem onClick={handleDashboardClick}>
                                    <ListItemText primary="Dashboard" />
                                </MenuItem>
                                <MenuItem onClick={logout}>
                                    <ListItemText primary="Logout" />
                                </MenuItem>
                            </>
                        ) : (
                            <MenuItem onClick={handleLoginLogout}>
                                <ListItemText primary="Login" />
                            </MenuItem>
                        )}
                    </Menu>
                </MenuIconWrapper>
                <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>

                    <StyledMenuItemsContainer>
                        <StyledButton component={RouterLink} to="/firstPage" color="inherit">Home</StyledButton>
                        <StyledButton component={RouterLink} to="/FoodAnalysis" color="inherit">Food Analysis</StyledButton>
                        <StyledButton
                            aria-controls="admission-menu"
                            aria-haspopup="true"
                            onClick={(e) => handleMenuOpen(e, 'admission')}
                            color="inherit"
                        >
                            Admission {anchorElAdmission ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </StyledButton>
                        <StyledMenu
                            id="admission-menu"
                            anchorEl={anchorElAdmission}
                            open={Boolean(anchorElAdmission)}
                            onClose={() => handleMenuClose('admission')}
                        >
                            <StyledMenuItem component={RouterLink} to="/enquiry" onClick={() => handleMenuClose('admission')}>
                                <ListItemIcon><SchoolIcon fontSize="small" /></ListItemIcon>
                                <ListItemText primary="Enquiry" />
                            </StyledMenuItem>
                            <StyledMenuItem component={RouterLink} to="/fee" onClick={() => handleMenuClose('admission')}>
                                <ListItemIcon><LibraryBooksIcon fontSize="small" /></ListItemIcon>
                                <ListItemText primary="Fee" />
                            </StyledMenuItem>
                        </StyledMenu>
                        <StyledButton
                            aria-controls="facility-menu"
                            aria-haspopup="true"
                            onClick={(e) => handleMenuOpen(e, 'facility')}
                            color="inherit"
                        >
                            Facility {anchorElFacility ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </StyledButton>
                        <StyledMenu
                            id="facility-menu"
                            anchorEl={anchorElFacility}
                            open={Boolean(anchorElFacility)}
                            onClose={() => handleMenuClose('facility')}
                        >
                            <StyledMenuItem component={RouterLink} to="/faculty" onClick={() => handleMenuClose('facility')}>
                                <ListItemIcon><SchoolIcon fontSize="small" /></ListItemIcon>
                                <ListItemText primary="Faculty" />
                            </StyledMenuItem>
                            <StyledMenuItem component={RouterLink} to="/library" onClick={() => handleMenuClose('facility')}>
                                <ListItemIcon><LibraryBooksIcon fontSize="small" /></ListItemIcon>
                                <ListItemText primary="Library" />
                            </StyledMenuItem>
                            <StyledMenuItem component={RouterLink} to="/extracurricular" onClick={() => handleMenuClose('facility')}>
                                <ListItemIcon><StarsIcon fontSize="small" /></ListItemIcon>
                                <ListItemText primary="ExtraCurricular" />
                            </StyledMenuItem>
                            <StyledMenuItem component={RouterLink} to="/transportation" onClick={() => handleMenuClose('facility')}>
                                <ListItemIcon><CommuteIcon fontSize="small" /></ListItemIcon>
                                <ListItemText primary="Transportation" />
                            </StyledMenuItem>
                        </StyledMenu>
                        <StyledButton
                            aria-controls="careers-menu"
                            aria-haspopup="true"
                            onClick={(e) => handleMenuOpen(e, 'careers')}
                            color="inherit"
                        >
                            Careers {anchorElCareers ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </StyledButton>
                        <StyledMenu
                            id="careers-menu"
                            anchorEl={anchorElCareers}
                            open={Boolean(anchorElCareers)}
                            onClose={() => handleMenuClose('careers')}
                        >
                            <StyledMenuItem component={RouterLink} to="/achievement" onClick={() => handleMenuClose('careers')}>
                                <ListItemIcon><StarsIcon fontSize="small" /></ListItemIcon>
                                <ListItemText primary="Achievement" />
                            </StyledMenuItem>
                            <StyledMenuItem component={RouterLink} to="/alumni" onClick={() => handleMenuClose('careers')}>
                                <ListItemIcon><PeopleIcon fontSize="small" /></ListItemIcon>
                                <ListItemText primary="Our Alumni" />
                            </StyledMenuItem>
                        </StyledMenu>
                    </StyledMenuItemsContainer>
                </Box>

                <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                    {isLoggedIn ? (
                        <>

                            <StyledButton color="inherit" onClick={logout}>Logout</StyledButton>
                            <StyledButton color="inherit" onClick={handleDashboardClick}>Dashboard</StyledButton>
                        </>
                    ) : (
                        <StyledButton component={RouterLink} to="/loginPage" color="inherit">Login</StyledButton>
                    )}
                </Box>

            </StyledToolbar>
        </StyledAppBar>
    );
};

export default Navbar;

const StyledAppBar = styled(AppBar)`
    background-color: #072D55 !important; /* Updated background color to dark blue */
    height: 6rem; /* Increased height of the navbar */
    display: flex;
    justify-content: center;
  
   
`;

const StyledToolbar = styled(Toolbar)`
    display: flex;
    justify-content: space-between;
   
`;

const StyledLogo = styled.img`
    max-height: 4rem; /* Adjusted logo size */
    margin-right: 1rem;
    filter: invert(1); /* Inverted colors to make text white */
  
`;



const StyledMenuItemsContainer = styled.div`
    display: flex;
    align-items: center;
   
    @media (max-width: 768px) {
        display: ${(props) => (props.showMenu ? 'block' : 'none')}; /* Show menu items if menu is open */
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: #072D55; /* Dark blue background */
        z-index: 1000;
        flex-direction: column;
        align-items: flex-start;
        padding: 1rem 0;
    }
`;

const StyledButton = styled(Button)`
    text-transform: none;
    display: flex;
    align-items: center;
    margin-left: 1rem;
    margin-right: 1rem;
    &:hover {
        background-color: rgba(255, 255, 255, 0.1); /* Lighten the background on hover */
    }
    @media (max-width: 768px) {
        width: 100%; /* Full width on smaller screens */
        justify-content: flex-start; /* Align text to the left */
        padding: 1rem; /* Add padding for touch targets */
    }
`;

const StyledMenu = styled(Menu)`
    .MuiPaper-root {
        background-color: #f9f9f9; /* Light grey background for menu */
    }
`;

const StyledMenuItem = styled(MenuItem)`
    text-transform: none;
    min-width: 200px; /* Adjusted width as needed */
    display: flex;
    align-items: center;
    &:hover {
        background-color: #e0e0e0; /* Light grey background on hover */
    }
`;

const MenuIconBar = styled.div`
    width: 1.5rem;
    height: 0.2rem;
    background-color: #fff;
    border-radius: 10px;
`;

const MenuIconWrapper = styled.div`
    display: none; /* Hide by default for larger screens */
   position: absolute;
   right: 0;
    @media (max-width: 768px) {
        display: flex; /* Display for smaller screens */
        align-items: center;
        
    }
`;
