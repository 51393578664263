// // src/FileUpload.js
// import React, { useState } from 'react';
// import axios from 'axios';
// import Navbar from '../Navbar';
// import './FoodAnalysis.css'; 

// const FoodAnalysis = () => {
//   const [file, setFile] = useState(null);
//   const [result, setResult] = useState(null);

//   const handleFileChange = (event) => {
//     setFile(event.target.files[0]);
//   };

//   const handleUpload = async () => {
//     if (!file) {
//       alert('Please select a file first.');
//       return;
//     }

//     const formData = new FormData();
//     formData.append('file', file);

//     try {
//       const response = await axios.post('https://api.isnartecherp.online/api/v1/foodAnalysis', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });
//       setResult(response.data);
//     } catch (error) {
//       console.error('Error uploading file:', error);
//       alert('Failed to analyze the food.');
//     }
//   };

//   return (
    
//     <div className="container">
//         <Navbar></Navbar>
//       <input type="file" className="upload-button" onChange={handleFileChange} />
//       <button className="upload-button" onClick={handleUpload}>Upload and Analyze</button>
//       {result && (
//         <div className="result-container">
//           <h2 className="heading">Analysis Result</h2>
//           <pre className="result-text">{JSON.stringify(result, null, 2)}</pre>
//         </div>
//       )}
//     </div>
//   );
// };

// export default FoodAnalysis;


// src/FoodAnalysis.js
import React, { useState } from 'react';
import axios from 'axios';
import Navbar from '../Navbar';
import './FoodAnalysis.css'; 

const FoodAnalysis = () => {
  const [file, setFile] = useState(null);
  const [result, setResult] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please select a file first.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('https://api.isnartecherp.online/api/v1/foodAnalysis', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setResult(response.data);
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Failed to analyze the food.');
    }
  };

  return (
    <div className="container">
      <Navbar />
      <div className="intro">
        <h1 className="intro-title">Welcome to Food Analyzer</h1>
        <p className="intro-text">
          Upload an image, video, or PDF of your food item, and let our advanced analysis tools provide you with insights on its nutritional content and more.
        </p>
      </div>
      <div className="upload-section">
        <input type="file" className="upload-button" onChange={handleFileChange} />
        <button className="upload-button" onClick={handleUpload}>Upload and Analyze</button>
      </div>
      {result && (
        <div className="result-container">
          <h2 className="heading">Analysis Result</h2>
          <pre className="result-text">{JSON.stringify(result, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default FoodAnalysis;
