// import React, { useContext, useState } from 'react';
// import { Link as RouterLink, useNavigate } from 'react-router-dom';
// import {
//   Grid,
//   Paper,
//   Typography,
//   TextField,
//   CircularProgress,
//   Box,
//   styled,
//   IconButton,
//   InputAdornment,
//   FormControlLabel,
//   Checkbox,
// } from '@mui/material';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { LightPurpleButton } from '../components/buttonStyles';
// import { AuthContext } from './context/AuthContext';
// import { Visibility, VisibilityOff } from '@mui/icons-material';

// // Import background image (replace with your actual image import)
// import bgpic from "../assets/designlogin.jpg";

// const defaultTheme = createTheme();

// const StyledLink = styled(RouterLink)(({ theme }) => ({
//   color: theme.palette.primary.main,
//   textDecoration: 'none',
//   '&:hover': {
//     textDecoration: 'underline',
//   },
// }));



// const AdministratorLogin = () => {
//   const { administratorLogin } = useContext(AuthContext);
//   const navigate = useNavigate();

//   const [toggle, setToggle] = useState(false);  
//   const [emailError, setEmailError] = useState(false);
//   const [passwordError, setPasswordError] = useState(false);
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     // Regular email/password login
//     if (!email || !password) {
//       if (!email) setEmailError(true);
//       if (!password) setPasswordError(true);
//       return;
//     }

//     const success = await administratorLogin(email, password);

//   };

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     if (name === 'email') {
//       setEmail(value);
//       setEmailError(false);
//     }
//     if (name === 'password') {
//       setPassword(value);
//       setPasswordError(false);
//     }
//   };

//   const toggleVisibility = () => {
//     setToggle(!toggle);
//   };

//   return (
//     <ThemeProvider theme={defaultTheme}>
//       <Grid container component="main" sx={{ height: '100vh' }}>
//         <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
//           <Box
//             sx={{
//               my: 8,
//               mx: 4,
//               display: 'flex',
//               flexDirection: 'column',
//               alignItems: 'center',
//             }}
//           >
//             <Typography variant="h4" sx={{ mb: 2, color: "#2c2143" }}>
//               Administrator Login
//             </Typography>
//             <Typography variant="subtitle1" sx={{ mb: 1 }}>
//               Welcome! Please enter your credentials.
//             </Typography>
//             <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 2 }}>
//               <TextField
//                 margin="normal"
//                 required
//                 fullWidth
//                 id="email"
//                 label="Enter your email"
//                 name="email"
//                 autoComplete="email"
//                 autoFocus
//                 error={emailError}
//                 helperText={emailError && 'Email is required'}
//                 value={email}
//                 onChange={handleInputChange}
//               />
//               <TextField
//                 margin="normal"
//                 required
//                 fullWidth
//                 name="password"
//                 label="Password"
//                 type={toggle ? 'text' : 'password'}
//                 id="password"
//                 autoComplete="current-password"
//                 error={passwordError}
//                 helperText={passwordError && 'Password is required'}
//                 value={password}
//                 onChange={handleInputChange}
//                 InputProps={{
//                   endAdornment: (
//                     <InputAdornment position="end">
//                       <IconButton onClick={toggleVisibility}>
//                         {toggle ? (
//                           <Visibility />
//                         ) : (
//                           <VisibilityOff />
//                         )}
//                       </IconButton>
//                     </InputAdornment>
//                   ),
//                 }}
//               />

//               <LightPurpleButton
//                 type="submit"
//                 fullWidth
//                 variant="contained"
//                 sx={{ mt: 3 }}
//               >

//                   Login
//               </LightPurpleButton>


//             </Box>
//           </Box>
//         </Grid>
//         <Grid
//           item
//           xs={false}
//           sm={4}
//           md={7}
//           sx={{
//             backgroundImage: `url(${bgpic})`,
//             backgroundRepeat: 'no-repeat',
//             backgroundColor: (t) =>
//               t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
//             backgroundSize: 'cover',
//             backgroundPosition: 'center',
//           }}
//         />
//       </Grid>
//     </ThemeProvider>
//   );
// };

// export default AdministratorLogin;


import React, { useContext, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Grid,
  Paper,
  Typography,
  TextField,
  Box,
  styled,
  IconButton,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LightPurpleButton } from '../components/buttonStyles';
import { AuthContext } from './context/AuthContext';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import bgpic from "../assets/designlogin.jpg";

const defaultTheme = createTheme();

const StyledLink = styled(RouterLink)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const AdministratorLogin = () => {
  const { administratorLogin, sendForgotPasswordEmail } = useContext(AuthContext);
  const navigate = useNavigate();

  const [toggle, setToggle] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [emailForOtpError, setEmailForOtpError] = useState(false);
  const [userType, setUserType] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!email || !password) {
      if (!email) setEmailError(true);
      if (!password) setPasswordError(true);
      return;
    }

    await administratorLogin(email, password);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'email') {
      setEmail(value);
      setEmailError(false);
    }
    if (name === 'password') {
      setPassword(value);
      setPasswordError(false);
    }
  };

  const toggleVisibility = () => {
    setToggle(!toggle);
  };

  const handleForgotPassword = async () => {
    if (!forgotPasswordEmail || !userType) {
      setEmailError(true);
      return;
    }
    await sendForgotPasswordEmail(forgotPasswordEmail, userType);
    setForgotPasswordOpen(false);
    setForgotPasswordEmail('');
    setUserType('');
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="h4" sx={{ mb: 2, color: "#2c2143" }}>
              Administrator Login
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Welcome! Please enter your credentials.
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 2 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Enter your email"
                name="email"
                autoComplete="email"
                autoFocus
                error={emailError}
                helperText={emailError && 'Email is required'}
                value={email}
                onChange={handleInputChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={toggle ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                error={passwordError}
                helperText={passwordError && 'Password is required'}
                value={password}
                onChange={handleInputChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={toggleVisibility}>
                        {toggle ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                <StyledLink to="#" onClick={() => setForgotPasswordOpen(true)}>
                  Forgot your password?
                </StyledLink>
              </div>
              <LightPurpleButton type="submit" fullWidth variant="contained" sx={{ mt: 3 }}>
                Login
              </LightPurpleButton>


            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${bgpic})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      </Grid>

      {/* Forgot Password Dialog */}
      <Dialog open={forgotPasswordOpen} onClose={() => setForgotPasswordOpen(false)}>
        <DialogTitle>Forgot Password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            value={forgotPasswordEmail}
            onChange={(e) => setForgotPasswordEmail(e.target.value)}
            error={emailError}
            helperText={emailError && 'Email is required'}
          />
          <FormControl fullWidth variant="outlined" margin="dense">
            <InputLabel>User Type</InputLabel>
            <Select
              value={userType}
              onChange={(e) => setUserType(e.target.value)}
              label="User Type"
            >
              <MenuItem value="">
                <em>Select User Type</em>
              </MenuItem>
              <MenuItem value="administrator">Administrator</MenuItem>              
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', padding: '16px' }}>
          <Button
            onClick={() => setForgotPasswordOpen(false)}
            color="error"
            variant="contained"
            sx={{
              mr: 1,
              bgcolor: 'red',
              '&:hover': {
                bgcolor: 'darkred',
              },
              textTransform: 'none', // Prevent text from transforming to uppercase
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleForgotPassword}
            variant="contained"
            sx={{
              bgcolor: 'primary.main',
              '&:hover': {
                bgcolor: 'primary.dark',
              },
              textTransform: 'none', // Prevent text from transforming to uppercase
            }}
          >
            Send Reset Link
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default AdministratorLogin;
