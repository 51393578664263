


import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress from Material-UI
import './AuthProvider.css'; // Import CSS file for styling
import './AuthProvider.css'

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false); // State for loading indicator

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userType');
    setIsLoggedIn(false);
    navigate('/home');
  };

  const sendOTP = async (email) => {
    setLoading(true); // Set loading state to true when sending OTP
    try {
      const response = await fetch('https://api.isnartecherp.online/api/v1/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        toast.success('OTP sent to your email');
        navigate('/otp-verification', { state: { email } });
        return true;
      } else {
        toast.error('Failed to send OTP');
        return false;
      }
    } catch (error) {
      toast.error('Network Error');
      return false;
    } finally {
      setLoading(false); // Reset loading state regardless of success or failure
    }
  };

  const verifyOTP = async (email, otp) => {
    setLoading(true); // Set loading state to true when verifying OTP
    try {
      const response = await fetch('https://api.isnartecherp.online/api/v1/verifyOTP', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, otp }),
      });

      const data = await response.json();

      console.log(data.user);

      if (response.ok) {
        const { token, userType, schoolId, classTeacherDetails, rollNumber, _id, standard, section, fullName } = data.user;

        toast.success(`${userType} is logged in successfully`);

        localStorage.setItem('token', token); // Store token for persistent login
        localStorage.setItem('userType', userType);
        localStorage.setItem('schoolId', schoolId);
        localStorage.setItem('classTeacherDetails', JSON.stringify(classTeacherDetails));
        localStorage.setItem('rollNumber', rollNumber);
        localStorage.setItem('_id', _id);
        localStorage.setItem('standard', standard);
        localStorage.setItem('section', section);
        localStorage.setItem('fullName', fullName);
        setIsLoggedIn(true);

        // Redirect based on userType
        navigate('/firstPage', { state: { schoolId } });
        return true;
      } else {
        toast.error('OTP verification failed');
        return false;
      }
    } catch (error) {
      toast.error('Network Error');
      return false;
    } finally {
      setLoading(false); // Reset loading state regardless of success or failure
    }
  };

  const loginByPassword = async (email, password) => {
    try {
      setLoading(true);
      const response = await fetch('https://api.isnartecherp.online/api/v1/loginByPassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        setLoading(false);
        const { token, userType, schoolId, classTeacherDetails, rollNumber, _id, standard, section, fullName } = data.user;


        toast.success(`${userType} is logged in successfully`);

        localStorage.setItem('token', token); // Store token for persistent login
        localStorage.setItem('userType', userType);
        localStorage.setItem('schoolId', schoolId);
        localStorage.setItem('classTeacherDetails', JSON.stringify(classTeacherDetails));
        localStorage.setItem('rollNumber', rollNumber);
        localStorage.setItem('_id', _id);
        localStorage.setItem('standard', standard);
        localStorage.setItem('section', section);
        localStorage.setItem('fullName', fullName);
        setIsLoggedIn(true);

        // Redirect based on userType
        navigate('/firstPage', { state: { schoolId } });

        return true; // Successful login
      } else {
        toast.error('Failed to login');
        setLoading(false);
        return false; // Failed login
      }
    } catch (error) {
      toast.error('Network Error');
      setLoading(false);
      return false; // Failed login
    }
  };

  const administratorLogin = async (email, password) => {
    try {
      setLoading(true);
      const response = await fetch('https://api.isnartecherp.online/api/v1/schoolLogin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      console.log(data);
      console.log(data.school);
      if (response.ok) {
        setLoading(false);
        const { schoolName, schoolId, email, _id } = data.school;
        toast.success('adminstrator is logged in successfully');
        setIsLoggedIn(true);
        localStorage.setItem('schoolId', schoolId);
        localStorage.setItem('schoolName', schoolName);
        localStorage.setItem('userId', _id);

        navigate('/firstPage', { state: { schoolName, schoolId, email } });


        return true; // Successful login
      } else {
        toast.error('Failed to login');
        setLoading(false);
        return false; // Failed login
      }
    } catch (error) {
      toast.error('Network Error');
      setLoading(false);
      return false; // Failed login
    }
  };

  const sendForgotPasswordEmail = async (email, userType) => {
    setLoading(true);
    try {
      const response = await fetch('https://api.isnartecherp.online/api/v1/forgotPassword', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, userType }),
      });

      if (response.ok) {
        toast.success('Password reset link sent to your email');
        return true;
      } else {
        toast.error('Failed to send password reset email');
        return false;
      }
    } catch (error) {
      toast.error('Network Error');
      return false;
    } finally {
      setLoading(false);
    }
  };


  return (
    <AuthContext.Provider value={{ isLoggedIn, logout, sendOTP, verifyOTP, loginByPassword, administratorLogin, sendForgotPasswordEmail }}>
      {loading && (
        <div className="loading-overlay">
          <CircularProgress size={60} thickness={4} color="primary" />
        </div>
      )}
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
