import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { AuthProvider } from './pages/context/AuthContext';
import Homepage from './pages/Homepage';
import AdminDashboard from './pages/admin/AdminDashboard';
import LoginPage from './pages/LoginPage';
import ChooseUser from './pages/ChooseUser';
import StudentDashboard from './pages/student/stuDashboard';
import PrivateRoute from './pages/PrivateRoute';
import FacDashboard from './pages/Faculty/FacDashboard';
import GuardedRoute from './pages/GaurdRoute';
import OTPVerificationPage from './pages/OTPVerification';
import StaffDashboard from './pages/Staff/staffDash';
import RegisterSchool from './pages/RegisterSchool';
import FirstPage from './pages/FirstPage';
import Dashboard from './pages/Adminstrator/Dashboard';
import AdministratorLogin from './pages/AdminstratorLogin'; 
import ContactForm from './pages/Contact/contactForm';
import ErpNavbar from './pages/ErpNavbar';
import FoodAnalysis from './pages/SchoolHomePage/FoodAnalysis';

const App = () => {
  // Hardcoded userType for testing purposes
  const userType = localStorage.getItem('userType'); // Assuming you store userType in localStorage

  return (

    <div className='bg-gradient-to-r from-gray-900 to-gray-200 w-screen h-screen overflow-x-hidden overflow-scroll'>
      <ErpNavbar/>
      <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/firstPage" element={ <PrivateRoute>
          <FirstPage />
        </PrivateRoute>}/>
      <Route path="/choose" element={<ChooseUser visitor="normal" />} />
      <Route path="/otp-verification" element={<OTPVerificationPage />} />
      <Route path="/loginPage" element={<LoginPage />} />
      <Route path='/adminstratorLogin' element={<AdministratorLogin/>}/>
      <Route path="/contactForm" element={<ContactForm />} />
     
    <Route path="/registerSchool" element={<RegisterSchool/>}/>
      <Route path="/Admin/dashboard" element={
        <PrivateRoute>
          <AdminDashboard />
        </PrivateRoute>
      } />
       <Route path="/Adminstrator/dashboard" element={
        <PrivateRoute>
          <Dashboard/>
        </PrivateRoute>
      } />
      <Route path="/StudentDashboard" element={
        <PrivateRoute>
          <StudentDashboard />
        </PrivateRoute>
      } />
      <Route path="/FacultyDashboard" element={
        <PrivateRoute>
          <FacDashboard />
        </PrivateRoute>
      } />
      <Route path="/StaffDashboard" element={
        <PrivateRoute>
          <StaffDashboard />
        </PrivateRoute>
      } />
      <Route path="/FoodAnalysis" element={
        <PrivateRoute>
          <FoodAnalysis/>
        </PrivateRoute>
      } />
      <Route path='*' element={<Navigate to="/" />} />
    </Routes>

    </div>
  );
}

export default App;


