


import React, { useState } from 'react';
import axios from 'axios';
import { Grid, Paper, Typography, TextField, Checkbox, FormControlLabel, CircularProgress } from '@mui/material';
import { LightPurpleButton } from '../components/buttonStyles';
import { styled } from '@mui/system';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const StyledFormContainer = styled('div')({
  marginTop: 20,
});


const TransparentTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
 

});

const RegisterSchool = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isFreeTrial, setIsFreeTrial] = useState(true);
  const [formData, setFormData] = useState({
    schoolName: '',
    address: '',
    email: '',
    password: '',
    confirmPassword: '',
    description: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRegistration = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      const response = await axios.post('https://api.isnartecherp.online/api/v1/schoolSignup', {
        schoolName: formData.schoolName,
        address: formData.address,
        email: formData.email,
        password: formData.password,
        confirmPassword: formData.confirmPassword,
        description: formData.description,
        subscriptionType: isFreeTrial ? 'free' : 'paid',
      });

      if (response.data.success) {
        toast.success("School registered successfully. You can access your school on ERP after 24 hrs to 48 hrs.");
        navigate('/');
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.error('Error during registration:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StyledFormContainer>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} md={6} marginTop={15} marginBottom={5} backgroundColor='#D6DBDF' padding={4} className=' mt-[4rem]'>
         
            <Typography variant="h4" gutterBottom align='center' >
              Register Your School
            </Typography>
            <form onSubmit={handleRegistration} style={{ width: '100%' }}>
              <TransparentTextField
                margin="normal"
                required
                fullWidth
                id="schoolName"
                label="School Name"
                name="schoolName"
                autoFocus
               
                value={formData.schoolName}
                onChange={handleChange}
              />
              <TransparentTextField
                margin="normal"
                required
                fullWidth
                id="address"
                label="Address"
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
              <TransparentTextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
              />
              <TransparentTextField
                margin="normal"
                required
                fullWidth
                id="password"
                label="Password"
                name="password"
                type="password"
                value={formData.password}
                onChange={handleChange}
              />
              <TransparentTextField
                margin="normal"
                required
                fullWidth
                id="confirmPassword"
                label="Confirm Password"
                name="confirmPassword"
                type="password"
                value={formData.confirmPassword}
                onChange={handleChange}
              />
              <TransparentTextField
                margin="normal"
                required
                fullWidth
                id="description"
                label="Add Description About Your School"
                name="description"
                value={formData.description}
                onChange={handleChange}
                multiline
                rows={4}
              />
              <FormControlLabel
                control={<Checkbox checked={isFreeTrial} onChange={() => setIsFreeTrial(!isFreeTrial)} />}
                label="Sign up for a free 365-days trial"
              />
              <LightPurpleButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3 }}
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : 'Register'}
              </LightPurpleButton>
            </form>
            <Typography variant="body2" sx={{ mt: 2 }}>
              Already have an account?{' '}
              <RouterLink to="/loginPage" variant="body2">
                Login
              </RouterLink>
            </Typography>
        
        </Grid>
      </Grid>
    </StyledFormContainer>
  );
};

export default RegisterSchool;
